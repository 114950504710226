import React from "react";

const HomeSteps = () => {
  const data = [
    {
      title: "Fill Form",
    },
    {
      title: "Upload Resume",
    },
    {
      title: "Connect With Companies",
    },
  ];
  return (
    <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
      <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
        <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto">
          <span className="relative inline-block">
            <span className="relative text-transparent bg-clip-text bg-gradient-to-br from-primary to-secondary">
              Are you looking for a dream job?
            </span>
          </span>
        </h2>
        <p className="text-base text-gray-700 md:text-lg">
          To apply, simply fill out the form below, attach your resume, and
          provide a brief overview of your qualifications and aspirations. Our
          recruitment team will carefully review your application and reach out
          to you if there is a suitable match.
        </p>
      </div>
      <div className="grid gap-8 row-gap-0 lg:grid-cols-3">
        {data.map((item, index) => (
          <div className="relative text-center ">
            <div className="flex items-center justify-center w-16 h-16 mx-auto mb-4 rounded-full bg-indigo-50 sm:w-20 sm:h-20 bg-gradient-to-br from-primary to-secondary">
              <h6 className="text-3xl font-bold text-white">{index + 1}</h6>
            </div>
            <h6 className="mb-2 text-2xl font-bold">{item.title}</h6>

            {index < 2 && (
              <div className="top-0 right-0 flex items-center justify-center h-24 lg:-mr-8 lg:absolute ">
                <svg
                  className="w-8 text-gray-700 transform rotate-90 lg:rotate-0 "
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  viewBox="0 0 24 24"
                >
                  <line
                    fill="none"
                    strokeMiterlimit="10"
                    x1="2"
                    y1="12"
                    x2="22"
                    y2="12"
                  />
                  <polyline
                    fill="none"
                    strokeMiterlimit="10"
                    points="15,5 22,12 15,19 "
                  />
                </svg>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default HomeSteps;
