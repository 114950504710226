import HomeHeader from "../components/HomeHeader";
import HomeAbout from "../components/HomeAbout";
import HomeServices from "../components/HomeServices";

import HomeContact from "../components/HomeContact";
import HomeCollabs from "../components/HomeCollabs";
import HomeIndustries from "../components/HomeIndustries";
import HomeCta from "../components/HomeCta";
import HomeSteps from "../components/HomeSteps";
import HomeTestimonials from "../components/HomeTestimonials";
import { useEffect } from "react";
import HomeCarousel from "../components/HomeCarousel";

export const Home = () => {
  // useEffect(() => {
  //   fetch("/jobs")
  //     .then((res) => res.json())
  //     .then((data) => console.log(data.message));
  // }, []);
  return (
    <>
      <HomeCarousel />
      {/* <HomeHeader /> */}
      <HomeSteps />
      <HomeCta />

      <HomeServices />
      <HomeCollabs />
      <HomeAbout />
      <HomeIndustries />
      <HomeTestimonials />
    </>
  );
};
