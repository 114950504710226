import { Carousel, Typography, Button } from "@material-tailwind/react";

export default function Example() {
  return (
    <Carousel>
      <div className="relative h-full w-full bg-white">
        <img
          src="https://images.pexels.com/photos/288477/pexels-photo-288477.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
          alt="image 1"
          className="h-full w-full object-cover"
          style={{ height: "100vh" }}
        />
        <div className="absolute inset-0 grid h-full w-full place-items-center bg-black/75">
          <div className="w-3/4 text-center md:w-2/4">
            <Typography
              variant="h1"
              color="white"
              className="mb-4 text-3xl md:text-4xl lg:text-5xl"
            >
              Welcome to Arch
              <br className="hidden md:block" />
              consulting and {"\n\n"}
              recruiting Inc!
            </Typography>
            <Typography
              variant="lead"
              color="white"
              className="mb-12 opacity-80"
            >
              At our consulting and recruiting company, we believe in building
              long-term relationships with our clients based on trust, respect,
              and results. <br></br> We are a team of experienced professionals
              dedicated to helping businesses and individuals achieve their
              goals through strategic consulting and effective recruitment.
            </Typography>
            <div className="flex justify-center gap-2">
              <Button size="lg" color="white">
                Services
              </Button>
              <Button size="lg" color="white" variant="text">
                Contact Us
              </Button>
            </div>
          </div>
        </div>
      </div>

      <div className="relative h-full w-full">
        <img
          src="https://images.pexels.com/photos/288477/pexels-photo-288477.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
          alt="image 2"
          className="h-full w-full object-cover"
          style={{ height: "100vh" }}
        />
        <div className="absolute inset-0 grid h-full w-full items-center bg-black/75">
          <div className="w-3/4 pl-12 md:w-2/4 md:pl-20 lg:pl-32">
            <Typography
              variant="h1"
              color="white"
              className="mb-4 text-3xl md:text-4xl lg:text-5xl"
            >
              Welcome to Arch
              <br className="hidden md:block" />
              consulting and {"\n\n"}
              recruiting Inc!
            </Typography>
            <Typography
              variant="lead"
              color="white"
              className="mb-12 opacity-80"
            >
              At our consulting and recruiting company, we believe in building
              long-term relationships with our clients based on trust, respect,
              and results. <br></br> We are a team of experienced professionals
              dedicated to helping businesses and individuals achieve their
              goals through strategic consulting and effective recruitment.
            </Typography>
            <div className="flex gap-2">
              <Button size="lg" color="white">
                Services
              </Button>
              <Button size="lg" color="white" variant="text">
                Contact Us
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Carousel>
  );
}
