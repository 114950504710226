import React from "react";
import Data from "../data/Data";
import slack from "../assets/slack.png";

const HomeCollabs = () => {
  return (
    <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
      <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
        <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight sm:text-5xl md:mx-auto  text-transparent bg-clip-text bg-gradient-to-br from-primary to-secondary">
          Our Clients
        </h2>
        <p className="text-base text-gray-700 md:text-lg">
          Sed ut perspiciatis unde omnis iste natus error sit voluptatem
          accusantium doloremque rem aperiam, eaque ipsa quae.
        </p>
      </div>
      <div className="relative w-full p-px mx-auto mb-4 overflow-hidden transition-shadow duration-300 border border-lightGray rounded lg:mb-8 lg:max-w-4xl group hover:shadow-xl">
        <div className="absolute bottom-0 left-0 w-full h-1 duration-300 origin-left transform scale-x-0 bg-primary group-hover:scale-x-100" />
        <div className="absolute bottom-0 left-0 w-1 h-full duration-300 origin-bottom transform scale-y-0 bg-primary group-hover:scale-y-100" />
        <div className="absolute top-0 left-0 w-full h-1 duration-300 origin-right transform scale-x-0 bg-primary group-hover:scale-x-100" />
        <div className="absolute bottom-0 right-0 w-1 h-full duration-300 origin-top transform scale-y-0 bg-primary group-hover:scale-y-100" />
        <div className="relative flex flex-col items-center h-full py-10 duration-300 bg-white rounded-sm transition-color sm:items-stretch sm:flex-row">
          {[1, 2, 3, 4, 5, 6].map((item, index) => (
            <>
              <div className="px-8 py-8 text-center">
                <img src={slack} />
              </div>
              {index < 5 && (
                <div className="w-56 h-1 transition duration-300 transform bg-gray-300 rounded-full group-hover:bg-primary group-hover:scale-110 sm:h-auto sm:w-0.5" />
              )}
            </>
          ))}
        </div>
      </div>
    </div>
  );
};

export default HomeCollabs;
