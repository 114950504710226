import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Typography,
  Button,
} from "@material-tailwind/react";

export default function CtaCard({ data }) {
  const { title, description, imageUri, route } = data;
  return (
    <Card className="w-full">
      <CardHeader color="blue-gray" className="relative h-56">
        <img src={imageUri} alt="img-blur-shadow" layout="fill" />
      </CardHeader>
      <CardBody>
        <Typography variant="h5" color="blue-gray" className="mb-2">
          {title}
        </Typography>
        <Typography>{description}</Typography>
      </CardBody>
      <CardFooter className="pt-0">
        <Button>Learn More</Button>
      </CardFooter>
    </Card>
  );
}
