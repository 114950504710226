import { useEffect } from "react";
import JobCard from "./JobCard";
import axios from "axios";
import SearchBar from "./SearchBar";

const JobList = ({ handleTagClick, filteredJobs, onClickJobCard }) => {
  // useEffect(() => {
  //   let config = {
  //     headers: {
  //       mode: "no-cors",
  //       Authorization:
  //         "Zoho-oauthtoken 1000.affefba1a1dae9bfe1014c679f8a9d1e.ec318596b2c6dc08f744b23e9f25fd88",
  //       "Content-Type": "application/json",
  //       // "Access-Control-Allow-Headers":
  //       //   "Content-Type,X-Amz-Date,Authorization,X-Api-Key,X-Amz-Security-Token",
  //       // "Access-Control-Allow-Methods": "OPTIONS,POST",
  //       // "Access-Control-Allow-Credentials": true,
  //       "Access-Control-Allow-Origin": "http://localhost:3000",
  //       // "X-Requested-With": "*",
  //     },
  //   };
  //   axios
  //     .get("https://recruit.zoho.in/recruit/v2/JobOpenings", config)
  //     .then((res) => console.log(res))
  //     .catch((err) => console.log(err));
  // }, []);

  useEffect(() => {
    fetch("https://recruit.zoho.in/recruit/v2/JobOpenings", {
      method: "GET",
      mode: "no-cors",
      headers: {
        Authorization:
          "Zoho-oauthtoken 1000.affefba1a1dae9bfe1014c679f8a9d1e.ec318596b2c6dc08f744b23e9f25fd88",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "http://localhost:3000",
      },
      // body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((response) => console.log(response))
      .catch((err) => console.error(err));
  }, []);

  return (
    <div className="max-w-7xl mx-auto">
      <SearchBar />
      <div className="grid gap-16 row-gap-10 mb-8 mt-10 lg:grid-cols-3">
        {filteredJobs.map((job, index) => (
          <JobCard job={job} key={index} onClick={onClickJobCard} />
        ))}
      </div>
    </div>
  );
};

export default JobList;
