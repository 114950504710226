import React from "react";
import Data from "../data/Data";

const HomeIndustries = () => {
  return (
    <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
      <div className="flex flex-col mb-6 lg:justify-between lg:flex-row md:mb-8">
        <h2 className="max-w-lg mb-5 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none md:mb-6 group">
          <span className="inline-block mb-1 sm:mb-4 text-transparent bg-clip-text bg-gradient-to-br from-primary to-secondary">
            Industries we render staffing and{"\n"}
            {/* <br className="hidden md:block" /> */}
            consultancy services
          </span>
          <div className="h-1 ml-auto duration-300 origin-left transform bg-primary scale-x-30 group-hover:scale-x-100" />
        </h2>
        {/* <p className="text-gray-700 lg:text-sm lg:max-w-md">
          "Class is dead", says Foucault; however, according to Reicher, it is
          not so much class that is dead, but rather the absurdity, and some
          would say the paradigm, of class. However, the subject is interpolated
          into a postpatriarchialist dialectic theory that includes
          consciousness as a totality.
        </p> */}
      </div>
      <div className="grid gap-6 row-gap-5 mb-8 lg:grid-cols-3 sm:row-gap-6 sm:grid-cols-2">
        {Data.industries.map((item, index) => (
          <a href="/industries" aria-label="View Item">
            <div className="relative overflow-hidden transition duration-200 transform rounded shadow-lg hover:-translate-y-2 hover:shadow-2xl">
              <img
                className="object-cover w-full h-56 md:h-64 xl:h-80"
                src={item.imageUri}
                alt=""
              />
              <div className="absolute inset-x-0 bottom-0 px-6 py-4 bg-primary bg-opacity-75">
                <p className="text-medium font-medium tracking-wide text-white">
                  {item.title}
                </p>
              </div>
            </div>
          </a>
        ))}
      </div>
      <div className="text-center">
        <a
          href="/industries"
          aria-label=""
          className="inline-flex items-center font-semibold transition-colors duration-200 text-primary hover:text-primary-800"
        >
          View Industries
          <svg
            className="inline-block w-3 ml-2"
            fill="currentColor"
            viewBox="0 0 12 12"
          >
            <path d="M9.707,5.293l-5-5A1,1,0,0,0,3.293,1.707L7.586,6,3.293,10.293a1,1,0,1,0,1.414,1.414l5-5A1,1,0,0,0,9.707,5.293Z" />
          </svg>
        </a>
      </div>
    </div>
  );
};

export default HomeIndustries;
