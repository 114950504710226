import React from "react";
import TestimonyCard from "./TestimonyCard";

const HomeTestimonials = () => {
  return (
    <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
      <div className="grid gap-10 row-gap-8 lg:grid-cols-5">
        <div className="lg:col-span-2">
          <div className="mb-3">
            <a
              href="/"
              aria-label="Article"
              className="inline-block text-black transition-colors duration-200 hover:text-primary"
            >
              <p className="font-sans text-xl font-extrabold leading-none tracking-tight lg:text-4xl xl:text-5xl  text-transparent bg-clip-text bg-gradient-to-br from-primary to-secondary">
                The Honest Review From Our Client
              </p>
            </a>
          </div>
          <p className="mb-4 text-base text-gray-700 md:text-lg">
            Call it magical realism, call it realistic fantasy—call it whatever
            you want, but Arimah's playfully subversive style is wholly her own.
          </p>
        </div>
        <div className="flex flex-col space-y-8 lg:col-span-3">
          <div className="px-6 py-6 border border-lightGray rounded-b sm:px-8">
            <p className="mb-4 text-base text-gray-700 md:text-lg">
              I highly recommend [Company Name] to any organization in search of
              top-quality professionals who are not only proficient in their
              respective fields but also quick to respond and engage in the
              hiring process
            </p>
            <div className="flex items-center">
              <a href="/" aria-label="Author" className="mr-3">
                <img
                  alt="avatar"
                  src="https://images.pexels.com/photos/91227/pexels-photo-91227.jpeg?auto=compress&amp;cs=tinysrgb&amp;dpr=2&amp;h=750&amp;w=1260"
                  className="object-cover w-10 h-10 rounded-full shadow-sm"
                />
              </a>
              <div>
                <a
                  href="/"
                  aria-label="Author"
                  className="font-semibold text-gray-800 transition-colors duration-200 hover:text-primary"
                >
                  Alex Stratulat
                </a>
                <p className="text-sm font-medium leading-4 text-gray-600">
                  Author
                </p>
              </div>
            </div>
          </div>
          <div className="px-6 py-6 border border-lightGray rounded-b sm:px-8">
            <p className="mb-4 text-base text-gray-700 md:text-lg">
              I highly recommend [Company Name] to any organization in search of
              top-quality professionals who are not only proficient in their
              respective fields but also quick to respond and engage in the
              hiring process
            </p>
            <div className="flex items-center">
              <a href="/" aria-label="Author" className="mr-3">
                <img
                  alt="avatar"
                  src="https://images.pexels.com/photos/104827/cat-pet-animal-domestic-104827.jpeg?auto=compress&amp;cs=tinysrgb&amp;dpr=3&amp;h=750&amp;w=1260"
                  className="object-cover w-10 h-10 rounded-full shadow-sm"
                />
              </a>
              <div>
                <a
                  href="/"
                  aria-label="Author"
                  className="font-semibold text-gray-800 transition-colors duration-200 hover:text-primary"
                >
                  Susie the Cat
                </a>
                <p className="text-sm font-medium leading-4 text-gray-600">
                  Author
                </p>
              </div>
            </div>
          </div>
          {/* <TestimonyCard /> */}
        </div>
      </div>
    </div>
  );
};

export default HomeTestimonials;
