import { Facebook, LinkedIn, Api, Webhook } from "@mui/icons-material";

import { SiJirasoftware } from "react-icons/si";
import { CgWebsite, CgSmartphoneChip } from "react-icons/cg";
import { AiFillCloud, AiFillRobot } from "react-icons/ai";
import { VscLightbulbAutofix } from "react-icons/vsc";

export default {
  //HomeHeader
  companyName: "Swab Nanosolutions",
  companyDescription:
    "SWAB Nanosolutions develops and commercialize nanotechnology based solutions, consumables and application for the Industries.​ As an aware global citizen, We believe in making tomorrow better for future generations.\n\nSWAB Nanosolutions is building a preeminent global R&D based industry focused on the discovery, development and commercialization of innovative technologies for industries and market.",
  companyImage:
    "https://base.imgix.net/files/base/ebm/wto/image/2019/04/WT0519_iStock-970829836_koya79.png?auto=format&w=720",

  //HomeAbout

  aboutQuote:
    '"BEST IDEAS AND RESULTS COME WHEN TWO PASSIONATE INDIVIDUALS WITH THE SIMILAR GOALS OF SERVING THE SOCIETY AND NATURE, UNITE TOGETHER AS A TEAM."',
  aboutText:
    "Swab Nanosolutions was founded by two young individuals, Dr. Sweta Bhansali and Abhinav Sirothia in 2019, with an aim to bring out science and technology from labs to your houses., Two young scientists after finishing their PhD and Master’s from prestigious universities of Europe, came together with a same goal of serving the society and nation by their knowledge and passion.\n\nWe at Swab Nanosolutions, aim to find solutions to your day to day problems by our expertise in the field of Nanotechnology. We are based in Delhi/NCR but we have global outreach with our international connections ranging from academia to Industries.\n\nWe aim to provide innovations based as per the need of the customers and the market.",

  socialMediaLinks: [
    {
      iconName: <Facebook />,
      url: "https://www.facebook.com/Swab.nanosolutions/",
    },
    {
      iconName: <LinkedIn />,
      url: "https://www.linkedin.com/company/swabnanosolutions",
    },
  ],

  services: [
    {
      iconName: <SiJirasoftware color="white" size={20} />,
      bigIcon: <SiJirasoftware color="white" size={42} />,
      title: "Software Development",
      shortDescription:
        "Empower your business with our exceptional software development service, delivering innovative solutions tailored to your needs and driving growth in the digital era.",
      description:
        "we are driven by a passion for creating tailored software solutions that effectively tackle intricate business problems. Our team of seasoned developers, designers, and project managers work in unison to provide superior software that aligns with the specific requirements of our clients. We cover the entire software development life cycle from ideation to delivery and ongoing support, and our unwavering commitment to delivering exceptional results that bolster business growth is second to none. With a laser focus on innovation, quality, and customer satisfaction, we are your reliable partner for all your software development needs.",
    },
    {
      iconName: <CgSmartphoneChip color="white" size={20} />,
      bigIcon: <CgSmartphoneChip color="white" size={42} />,
      title: "IT Project implementation",
      shortDescription:
        "Transforming your technology goals into reality, our IT project implementation service ensures smooth execution and successful deployment of your initiatives, delivering tangible results for your business.",
      description:
        "Welcome to our comprehensive IT project implementation company, where we have assembled a team of skilled project managers, experienced developers, and software testers - both manual and automation - to bring your digital visions to life. We offer end-to-end solutions that are customized to your specific business requirements, and we work closely with you from planning through execution and maintenance to ensure a smooth and successful project delivery. Our unwavering commitment to innovation, reliability, and customer satisfaction distinguishes us as your trusted partner in achieving your IT objectives.",
    },
    {
      iconName: <CgWebsite color="white" size={20} />,
      bigIcon: <CgWebsite color="white" size={42} />,
      title: "Website Designing",
      shortDescription:
        "Crafting captivating online experiences, our website designing service creates visually stunning and user-friendly websites that leave a lasting impression.",
      description:
        "We are a team of creative and experienced designers who specialize in crafting custom website designs that reflect the unique brand identities of our clients' businesses. We specialize in creating visually stunning and user-friendly websites that help businesses establish a strong online presence. Our team of skilled web designers and developers are committed to delivering top-quality website solutions that exceed our clients' expectations. Contact us today to learn more about our services and how we can help your business succeed online.",
    },
    {
      iconName: <AiFillCloud color="white" size={20} />,
      bigIcon: <AiFillCloud color="white" size={42} />,
      title: "Cloud Computing",
      shortDescription:
        "Unleash the power of the cloud with our comprehensive cloud computing service, providing scalable, secure, and cost-effective solutions that revolutionize your business operations.",
      description:
        "We provide top-of-the-line solutions to help businesses thrive in the digital age. Our team of experts offers a range of services, from cloud migration and deployment to infrastructure management and support. We are committed to delivering reliable, scalable, and cost-effective solutions that meet your specific needs. Let us help you optimize your operations and maximize your potential with the power of the cloud. Contact us today to learn more about how we can serve you.",
    },
    {
      iconName: <AiFillRobot color="white" size={20} />,
      bigIcon: <AiFillRobot color="white" size={42} />,
      title: "Robotic Process Automation",
      shortDescription:
        "Streamline operations, increase efficiency with our robotic process automation services.",

      description:
        "Unlock the potential of automation with our cutting-edge Robotics Process Automation (RPA) services. Our expert team leverages advanced technologies to streamline your business operations, eliminate manual tasks, and drive productivity to new heights. Experience seamless process optimization and significant cost savings as our RPA solutions revolutionize your organization's efficiency. Discover a new era of automation with our tailored RPA services today.",
    },
    {
      iconName: <VscLightbulbAutofix color="white" size={20} />,
      bigIcon: <VscLightbulbAutofix color="white" size={42} />,

      title: "IoT",
      shortDescription:
        "we are driven by a passion for creating tailored software solutions that effectively tackle intricate business problems. Our team of seasoned developers, designers, and project managers work in unison to provide superior software that aligns with the specific requirements of our clients.",

      description:
        "we are driven by a passion for creating tailored software solutions that effectively tackle intricate business problems. Our team of seasoned developers, designers, and project managers work in unison to provide superior software that aligns with the specific requirements of our clients. We cover the entire software development life cycle from ideation to delivery and ongoing support, and our unwavering commitment to delivering exceptional results that bolster business growth is second to none. With a laser focus on innovation, quality, and customer satisfaction, we are your reliable partner for all your software development needs.",
    },
  ],

  industries: [
    {
      imageUri:
        "https://images.pexels.com/photos/16129703/pexels-photo-16129703/free-photo-of-man-coding-on-computers-sitting-at-desk.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
      title: "IT Professionals",
      description:
        "We are dedicated to connecting businesses with top-tier IT talent to help them thrive in today's competitive landscape. Our team of experienced recruiters and HR professionals are committed to finding the right match for your company's unique needs, whether you're looking for temporary, contract-to-hire, or permanent staffing solutions. We understand that the success of your business depends on the quality of your workforce, and that's why we go above and beyond to ensure that our candidates have the skills, experience, and cultural fit that you need to succeed. Contact us today to learn more about how we can help you build a winning team of IT professionals.",
    },
    {
      imageUri:
        "https://images.pexels.com/photos/3434533/pexels-photo-3434533.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
      title: "Packaging",
      description:
        "We excel in providing skilled and experienced packaging professionals to businesses operating in a variety of industries. As a leading provider in the industry, our focus is on sourcing and delivering high-quality talent to meet the unique packaging needs of our clients.",
    },
    {
      imageUri:
        "https://images.pexels.com/photos/581344/pexels-photo-581344.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
      title: "Retail",
      description:
        "At ARCH's Retail Staffing wing, we maintain an extensive network of talented retail professionals with diverse skill sets and experience. Our team is committed to providing our clients with top-quality talent that aligns with their specific needs and helps them achieve their retail objectives. From sales associates and customer service representatives to inventory managers and visual merchandisers, our talented professionals have the expertise and knowledge to help our clients succeed. ",
    },
    {
      imageUri:
        "https://images.pexels.com/photos/789822/pexels-photo-789822.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
      title: "Communications",
      description:
        "We specialize in providing businesses across a variety of industries with top-quality call center professionals. Our talented specialists are experienced in customer service, technical support, sales, and more, and are dedicated to helping our clients achieve their call center objectives. Contact us today to learn more about our staffing solutions and how we can help your business succeed.",
    },
    {
      imageUri:
        "https://images.pexels.com/photos/2219024/pexels-photo-2219024.jpeg?auto=compress&cs=tinysrgb&w=1200",
      title: "Construction",
      description:
        "We provide skilled and reliable workers for all types of construction projects. Our team of experts understands the unique needs of the construction industry and works tirelessly to match the right workers with the right job. Whether you need general laborers or highly specialized tradespeople, we have the talent you need to get the job done efficiently and effectively. Our commitment to safety, quality, and customer satisfaction sets us apart from the competition. Let us help you build your team and take your construction projects to the next level!",
    },
    {
      imageUri:
        "https://images.pexels.com/photos/65623/vehicle-chrome-technology-automobile-65623.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
      title: "Automotive",
      description:
        "Looking for the right talent to fuel your automotive business? Our dedicated team of experts is here to help! We understand that the automotive industry is constantly evolving, which is why we work tirelessly to provide you with qualified and experienced professionals who can keep up with its demands. From skilled mechanics to engineers and salespeople, we have the resources and expertise to identify the perfect fit for your team. Our commitment to quality, safety, and customer satisfaction sets us apart from the rest, and we take pride in exceeding your expectations every time. Join hands with us to build a winning team and drive your automotive business forward.",
    },
  ],

  // coreMembers: [
  //   {
  //     imageUri: team1,
  //     title: "Dr. Sweta Bhansali",
  //     subtitle: "Researcher",
  //     description:
  //       "A spiritual soul who is a PhD and entrepreneur in nanotechnology. Phd in materials science with experience in fabrication of nano materials specifically nano composites for transparent conductive electrodes and thin films and superlattices through PVD processes for Thermoelectrics. Expertise in electrical and thermal measurements of thin film systems along with its microscopic and structural properties using XRD, SEM and HRTEM. Due to her passion to bring something useful and her work done on Nanomaterials during her Ph.D she was awarded young scientist award by European materials research society. she loves being volunteer and motivational mentor with NGOs mentor together, FEA, Naam Vidhaikalam of Tamil Nadu and SICAR in Spain. Her passions include Travelling, reading, writing poems and being a volunteer for spreading love and smiles and going for adventurous sports. she is also a NLP Practitioner and a toastmaster. she believes in working for humanity and well being of environment through her research and social work.",
  //     specialities: [
  //       "Green nanomaterials and nanocomposites fabrication",
  //       "Nano-composites for transparent electrode",
  //       "Nanomaterials for thermoelectricity",
  //       "Smart nanomaterials fabrication",
  //     ],
  //   },
  //   {
  //     imageUri: team2,
  //     title: "Abhinav Sirothia",
  //     subtitle: "Researcher",
  //     description:
  //       "A young scientist and an entrepreneur with a spiritual soul. He has served several reputed institutes - DRDO and TNO in the Netherlands. He has a track record of working in the field of synthesis and characterization of nanomaterials. He has been working on a position funded by European FP7 programs. He believes in bridging the gap between Academics and Industry. He also has experience of working in a pharma industry and responsibly understands the ethics and conduct of Industrial work culture. He manages the operations of World Spiritual Foundation, an expert in managing interpersonal relationships with International clients. He loves to travel, explore new opportunities, learn from others and live a happy and purposeful life.",
  //     specialities: [
  //       "Green Nanotechnology",
  //       "Nanomaterials based sensors",
  //       "Biopolymer nano composites synthesis",
  //       "Quantum Dots",
  //     ],
  //   },
  // ],

  // team: [
  //   {
  //     imageUri: team4,
  //     title: "Alan Ali",
  //     subtitle: "International Business Executive",
  //     description:
  //       "International Business Executive (International Business and Relationships)",
  //     socialMediaLinks: [
  //       {
  //         iconName: <LinkedIn />,
  //         url: "",
  //       },
  //     ],
  //   },
  //   {
  //     imageUri: team6,
  //     title: "Dr Emigdio Chavez",
  //     subtitle: "Scientific Advisor",
  //     description: "",
  //     socialMediaLinks: [
  //       {
  //         iconName: <LinkedIn />,
  //         url: "https://www.linkedin.com/in/emigdio-chavez-angel-0a487949/?originalSubdomain=es",
  //       },
  //     ],
  //   },
  //   {
  //     imageUri: team5,
  //     title: "Jonah Barnstein",
  //     subtitle: "Strategy Advisor",
  //     description: "",
  //     socialMediaLinks: [
  //       {
  //         iconName: <LinkedIn />,
  //         url: "https://www.linkedin.com/in/jonahbernstein/",
  //       },
  //     ],
  //   },
  //   {
  //     imageUri: team7,
  //     title: "Nivesh Choraria",
  //     subtitle: "Accounts & Finance",
  //     description: "",
  //     socialMediaLinks: [
  //       {
  //         iconName: <LinkedIn />,
  //         url: "",
  //       },
  //     ],
  //   },
  //   {
  //     imageUri: team3,
  //     title: "Poojashree Karnan",
  //     subtitle: "Researcher",
  //     description: "",
  //     socialMediaLinks: [
  //       {
  //         iconName: <LinkedIn />,
  //         url: "https://www.linkedin.com/in/poojasri-k-5071821ba/",
  //       },
  //     ],
  //   },
  //   {
  //     imageUri: team8,
  //     title: "Dr. Tinku Basu",
  //     subtitle: "Distinguished Scientific Advisor",
  //     description: "",
  //     socialMediaLinks: [
  //       {
  //         iconName: <LinkedIn />,
  //         url: "https://www.linkedin.com/in/tinku-basu-80647845/",
  //       },
  //     ],
  //   },
  // ],

  // collaborations: [
  //   {
  //     imageUri: collab1,
  //     title: "KSR college of Technology",
  //     subtitle: "",
  //   },
  //   {
  //     imageUri: collab2,
  //     title: "Amity University",
  //     subtitle: "",
  //   },
  //   {
  //     imageUri: collab3,
  //     title: "PSG college of technology",
  //     subtitle: "",
  //   },
  // ],

  aboutSteps: [
    {
      title: "Problems",
      subtitle:
        "To look out for the hidden problems in the solutions or the products that already exists.",
    },
    {
      title: "Solutions",
      subtitle:
        "Thinking of the best and working deliverable to solve the existing problem.",
    },
    {
      title: "Prototype",
      subtitle:
        "Designing and providing the solution according to the need of the customer and their business for the problem to be solved or the betterment of the existing technology.",
    },
    {
      title: "Deliverable",
      subtitle:
        "Making our customers happy to use our nanosolutions to make their existing product more better and environmental friendly.",
    },
  ],

  contactAddress: [
    {
      title: "Office 1",
      address:
        "SWAB NANOSOLUTIONS PRIVATE LIMITED Khasra NO. 38/10, Vill: Mundka, Near Mundka Metro Yard New Delhi, Delhi 110041, India",
      location:
        "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3500.254245134148!2d76.99984081508373!3d28.682040282398326!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d0f5a93642f25%3A0xc8ed28cea9ceeaef!2sMundka%20Udyog%20Nagar%2C%20Mundka%20Industrial%20Area%2C%20Mundka%2C%20Delhi%2C%20110081!5e0!3m2!1sen!2sin!4v1642064947571!5m2!1sen!2sin",
    },
    {
      title: "Office 2",
      address:
        "Swab Nanosolutions, 4th floor, J block Bionest, PSG step, PSG college of Technology, Peelamadu, Avinashi road, Coimbatore (T.N.) - 641004",
      location:
        "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3916.184343170766!2d76.9990501!3d11.0247918!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba8582f031fca27%3A0x774c312c589c8103!2sC%20Block%2C%20psg%20tech%2C%20Avinashi%20Rd%2C%20Peelamedu%2C%20Coimbatore%2C%20Tamil%20Nadu%20641004!5e0!3m2!1sen!2sin!4v1642065043840!5m2!1sen!2sin",
    },
  ],
  jobPositions: [
    "CIO and CTOs",
    "IS and IT Directors/Managers",
    "Project Managers/Development",
    "Enterprise/Software Architects",
    "Systems Architects",
    "Software Engineers/Developers",
    "Web Developers/Programmers",
    "Business Analysts",
    "Technical Writers",
    "Web and graphic Designers Database",
    "Administrators & Developers /Analysts",
    "QA Engineers/Leads/Managers",
    "Systems Analysts/Engineers & Admins",
    "Network Engineers/Admins",
    "Security Specialists",
    "Technical /Desktop Support",
    "Help Desk Support",
    "Scrum Masters/Product managers",
    "Software Testers & many More",
  ],
};
