import React from "react";
import CtaCard from "./CtaCard";

const HomeCta = () => {
  const data = [
    {
      title: "Find Candidates",
      description:
        "Begin your candidate search now and gain a competitive edge with the right talent for your team!",
      imageUri:
        "https://images.pexels.com/photos/3182796/pexels-photo-3182796.jpeg?auto=compress&amp;cs=tinysrgb&amp;dpr=2&amp;h=750&amp;w=1260",
      route: "",
    },
    {
      title: "Find Jobs",
      description:
        "Begin your job search now and pave the way to a successful and fulfilling career!",
      imageUri:
        "https://images.pexels.com/photos/5439148/pexels-photo-5439148.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
      route: "",
    },
  ];
  return (
    <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
      <div className="grid max-w-screen-lg gap-8 row-gap-5 md:row-gap-8 sm:mx-auto lg:grid-cols-2">
        {/* <div className="transition duration-300 transform bg-white rounded shadow-sm hover:-translate-y-1 hover:shadow md:text-center">
          <div className="relative">
            <img
              className="object-cover w-full h-64 rounded-t lg:h-80 xl:h-70"
              src="https://images.pexels.com/photos/3182796/pexels-photo-3182796.jpeg?auto=compress&amp;cs=tinysrgb&amp;dpr=2&amp;h=750&amp;w=1260"
              alt=""
            />
            <div className="absolute inset-0 bg-gray-800 bg-opacity-25" />
          </div>
          <div className="px-6 py-8 border border-gray rounded-b sm:px-8">
            <h5 className="mb-2 text-xl font-bold leading-none sm:text-2xl">
              Find Candidates
            </h5>
            <p className="mb-5 text-gray-700">
              Begin your candidate search now and gain a competitive edge with
              the right talent for your team!
            </p>
            <button
              type="submit"
              className="inline-flex items-center justify-center h-12 px-6 font-medium tracking-wide text-white transition duration-200 rounded shadow-md bg-primary hover:bg-primary focus:shadow-outline focus:outline-none"
            >
              Learn more
            </button>
          </div>
        </div>
        <div className="transition duration-300 transform bg-white rounded shadow-sm hover:-translate-y-1 hover:shadow md:text-center">
          <div className="relative">
            <img
              className="object-cover w-full h-64 rounded-t lg:h-80 xl:h-70"
              src="https://images.pexels.com/photos/5439148/pexels-photo-5439148.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
              alt=""
            />
            <div className="absolute inset-0 bg-gray-800 bg-opacity-25" />
          </div>
          <div className="px-6 py-8 border border-gray  rounded-b sm:px-8">
            <h5 className="mb-2 text-xl font-bold leading-none sm:text-2xl">
              Find Jobs
            </h5>
            <p className="mb-5 text-gray-700">
              Begin your job search now and pave the way to a successful and
              fulfilling career!
            </p>
            <a href="/jobs">
              <button
                type="submit"
                className="inline-flex items-center justify-center h-12 px-6 font-medium tracking-wide text-white transition duration-200 rounded shadow-md bg-primary hover:bg-primary focus:shadow-outline focus:outline-none"
              >
                Learn more
              </button>
            </a>
          </div>
        </div> */}
        {data.map((item, index) => (
          <CtaCard data={item} />
        ))}
      </div>
    </div>
  );
};

export default HomeCta;
